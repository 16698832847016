.card {
	.card-footer {
		.stats {
			i {
				margin-right: 9px;
			}
		}
		.legend {
			i {
				margin-right: 4px;
			}
		}
	}
}
