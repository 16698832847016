.navbar {
	// padding-top: $navbar-padding-base;
	// padding-bottom: $navbar-padding-base;
	min-height: 53px;
	margin-bottom: 20px;

	a {
		vertical-align: middle;

		&:not(.btn):not(.dropdown-item) {
			color: $white-color;
		}

		&.dropdown-item {
			color: $default-color;
		}
	}

	&.bg-white {
		.input-group .form-control,
		.input-group.no-border .form-control {
			color: $default-color;

			@include placeholder() {
				color: $default-color;
			}
		}
		.input-group-prepend .input-group-text i,
		.input-group-append .input-group-text i {
			color: $default-color;
			opacity: 0.5;
		}
	}

	.form-group,
	.input-group {
		margin: 0;
		margin-left: -3px;
		margin-right: 5px;

		.form-group-addon,
		.input-group-prepend .input-group-text,
		.input-group-append .input-group-text {
			color: $default-color;

			i {
				opacity: 1;
			}
		}

		&.no-border {
			.form-control {
				color: $default-color;

				@include placeholder() {
					color: $default-color;
				}
			}
		}
	}

	p {
		display: inline-block;
		margin: 0;
		line-height: 1.8em;
		font-size: 1em;
		font-weight: 400;
	}

	&.navbar-absolute {
		position: absolute;
		width: 100%;
		z-index: 1029;
	}

	.documentation & {
		&.fixed-top {
			left: 0;
			width: initial;
		}
	}

	.navbar-wrapper {
		// display: inline-flex;
		align-items: center;

		.navbar-minimize {
			padding-right: 10px;

			.btn {
				margin: 0;
			}
		}

		.navbar-toggle {
			.navbar-toggler {
				padding-left: 0;
			}

			&:hover {
				& .navbar-toggler-bar.bar2 {
					width: 22px;
				}
			}
		}
	}

	.navbar-nav {
		// .nav-link.btn {
		// 	padding: $padding-btn-vertical $padding-btn-horizontal;
		// 	&.btn-lg {
		// 		padding: $padding-large-vertical $padding-large-horizontal;
		// 	}
		// 	&.btn-sm {
		// 		padding: $padding-small-vertical $padding-small-horizontal;
		// 	}
		// }

		// .nav-link {
		// 	text-transform: uppercase;
		// 	font-size: $font-size-mini;
		// 	// padding: $padding-base-vertical $padding-base-horizontal;
		// 	line-height: $line-height-nav-link;
		// 	margin-right: 3px;

		// 	i.fa + p,
		// 	i.nc-icon + p {
		// 		margin-left: 3px;
		// 	}

		// 	i.fa,
		// 	i.nc-icon {
		// 		font-size: 18px;
		// 		position: relative;
		// 		top: 3px;
		// 		text-align: center;
		// 		width: 21px;
		// 	}

		// 	i.nc-icon {
		// 		top: 4px;
		// 		font-size: 16px;
		// 	}

		// 	&.profile-photo {
		// 		.profile-photo-small {
		// 			width: 27px;
		// 			height: 27px;
		// 		}
		// 	}

		// 	&.disabled {
		// 		opacity: 0.5;
		// 		color: $white-color;
		// 	}
		// }

		// .nav-item.active .nav-link:not(.btn),
		// .nav-item .nav-link:not(.btn):focus,
		// .nav-item .nav-link:not(.btn):hover,
		// .nav-item .nav-link:not(.btn):active {
		// 	border-radius: $border-radius-small;
		// 	color: $default-color;
		// }
	}

	.logo-container {
		width: 27px;
		height: 27px;
		overflow: hidden;
		margin: 0 auto;
		border-radius: 50%;
		border: 1px solid transparent;
	}

	.navbar-brand {
		text-transform: capitalize;
		font-size: $font-size-large-navbar;
		padding-top: $padding-base-vertical;
		padding-bottom: $padding-base-vertical;
		line-height: $line-height-nav-link;
	}

	.navbar-toggler {
		width: 37px;
		height: 27px;
		vertical-align: middle;
		outline: 0;
		cursor: pointer;

		& .navbar-toggler-bar.navbar-kebab {
			width: 3px;
			height: 3px;
			border-radius: 50%;
			margin: 0 auto;
		}
	}

	.button-dropdown {
		.navbar-toggler-bar:nth-child(2) {
			width: 17px;
		}
	}

	&.navbar-transparent {
		background-color: $transparent-bg !important;
		box-shadow: none;
		border-bottom: 1px solid #ddd;

		a:not(.dropdown-item):not(.btn) {
			color: $default-color;

			&.disabled {
				opacity: 0.5;
				color: $default-color;
			}
		}

		.button-bar {
			background: $default-color;
		}

		.nav-item .nav-link:not(.btn) {
			color: $default-color;
		}
		.nav-item.active .nav-link:not(.btn),
		.nav-item .nav-link:not(.btn):focus,
		.nav-item .nav-link:not(.btn):hover,
		.nav-item .nav-link:not(.btn):focus:hover,
		.nav-item .nav-link:not(.btn):active {
			color: $primary-color;
		}
	}

	&.bg-white {
		a:not(.dropdown-item):not(.btn) {
			color: $default-color;

			&.disabled {
				opacity: 0.5;
				color: $default-color;
			}
		}

		.button-bar {
			background: $default-color;
		}

		.nav-item.active .nav-link:not(.btn),
		.nav-item .nav-link:not(.btn):focus,
		.nav-item .nav-link:not(.btn):hover,
		.nav-item .nav-link:not(.btn):active {
			color: $info-color;
		}

		.logo-container {
			border: 1px solid $default-color;
		}
	}

	.navbar-collapse {
		.nav-item {
			a {
				font-size: $font-size-base;
			}
		}
	}
}

.bg-default {
	background-color: $default-color !important;
}

.bg-primary {
	background-color: $primary-color !important;
}

.bg-info {
	background-color: $info-color !important;
}

.bg-success {
	background-color: $success-color !important;
}

.bg-danger {
	background-color: $danger-color !important;
}

.bg-warning {
	background-color: $warning-color !important;
}

.bg-white {
	background-color: $white-color !important;
}
