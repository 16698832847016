div.separator {
	display: flex;
	align-items: center;
	text-align: center;
	color: $medium-gray;
	&::before,
	&::after {
		content: '';
		flex: 1;
		border-bottom: 1px solid $medium-gray;
	}
	&::before {
		margin-right: 0.5em;
	}
	&::after {
		margin-left: 0.5em;
	}
}
