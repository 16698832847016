.table {
	.img-wrapper {
		width: 40px;
		height: 40px;
		border-radius: 50%;
		overflow: hidden;
		margin: 0 auto;
	}

	.img-row {
		max-width: 60px;
		width: 60px;
	}

	.form-check {
		margin: 0;

		& label .form-check-sign::before,
		& label .form-check-sign::after {
			top: -17px;
			left: 4px;
		}
	}

	.btn {
		margin: 0;
	}

	small,
	.small {
		font-weight: 300;
	}

	.card-tasks .card-body & {
		margin-bottom: 0;

		> thead > tr > th,
		> tbody > tr > th,
		> tfoot > tr > th,
		> thead > tr > td,
		> tbody > tr > td,
		> tfoot > tr > td {
			padding-top: 0;
			padding-bottom: 0;
		}
	}

	> thead > tr > th {
		font-size: 14px;
		font-weight: $font-weight-bold;
		padding-bottom: 0;
		text-transform: uppercase;
		border: 0;
	}

	.radio,
	.checkbox {
		margin-top: 0;
		margin-bottom: 0;
		padding: 0;
		width: 15px;

		.icons {
			position: relative;
		}

		label {
			&:after,
			&:before {
				top: -17px;
				left: -3px;
			}
		}
	}
	> thead > tr > th,
	> tbody > tr > th,
	> tfoot > tr > th,
	> thead > tr > td,
	> tbody > tr > td,
	> tfoot > tr > td {
		padding: 12px 7px;
		vertical-align: middle;
	}

	.th-description {
		max-width: 150px;
	}
	.td-price {
		font-size: 26px;
		font-weight: $font-weight-light;
		margin-top: 5px;
		position: relative;
		top: 4px;
		text-align: right;
	}
	.td-total {
		font-weight: $font-weight-bold;
		font-size: $font-size-h5;
		padding-top: 20px;
		text-align: right;
	}

	.td-actions .btn {
		margin: 0px;
	}

	> tbody > tr {
		position: relative;
	}
}

.table-responsive {
	overflow-x: auto;
	padding-bottom: 10px;
}

#tables .table-responsive {
	margin-bottom: 30px;
}

.table-hover > tbody > tr:hover {
	background-color: #f5f5f5;
}
