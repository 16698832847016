.dropdown-menu {
	background-color: $white-color;
	border: 0 none;
	padding: 0px;
	padding-top: 0.5rem;
	// position: static !important;
	width: auto;
	margin-top: 1rem;
	border: 1px solid #e9e9e9;
	box-shadow: -1px 7px 15px 0px #bdbdbd;

	.dropdown.show {
		display: block !important;
		transform: translate3d(0px, 0px, 0px) !important;
	}

	.hr {
		padding: 0;
		margin: 0;
	}

	.dropdown-item {
		color: $font-color;
		font-size: $font-size-base;
		padding: 10px 45px 10px 15px;
		clear: both;
		white-space: nowrap;
		width: 100%;
		display: block;
	}

	.dropdown-item:focus {
		outline: 0 !important;
	}

	.dropdown-menu .dropdown-item:focus,
	.dropdown-menu .dropdown-item:hover {
		color: $white-color;
	}

	&.bg-white .dropdown-menu .dropdown-item:focus,
	&.bg-white .dropdown-menu .dropdown-item:hover {
		color: $default-color;
	}

	@media screen and (min-width: 991px) {
		position: absolute !important;
		margin-top: 0.5rem;
		box-shadow: 0 6px 10px -4px rgba(0, 0, 0, 0.15);
		padding: 1rem 0 0.25rem 0;
		width: 12rem;
		hr {
			margin: 1rem 0 0 0;
		}

		.dropdown-menu .dropdown-item {
			color: inherit;
		}

		.dropdown-item:hover,
		.dropdown-item:focus {
			color: $white-color;
			opacity: 1;
			text-decoration: none;
		}
		.dropdown-item:hover,
		.dropdown-item:focus {
			background-color: $default-color;
		}
	}
}
