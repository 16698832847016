form {
	.form-group {
		span.danger {
			font-size: 0.9rem;
			color: $color-red-error;
		}
	}

	label {
		margin: 0.7rem 0 0 0;
		display: block;
	}

	input,
	select {
		width: 100%;
		background-color: $white-color;
		border: 1px solid $medium-gray;
		border-radius: $border-radius-base;
		color: $font-color;
		line-height: $font-size-base;
		font-size: $font-size-base;
		padding: 0.4rem 0.5rem;
		@include transition-input-focus-color();
		@include box-shadow(none);
	}

	.errorMessage {
		font-size: 0.9rem;
		color: $danger-color;
		margin-top: 0.1rem;
		display: block;
	}
}
