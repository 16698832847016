div#productFilter {
	.inputAndSelectedItemsWrapper {
		position: relative;
		align-items: center;
	}
	input {
	}
	ul {
		padding: 0;
		position: relative;
		width: 100%;
		div {
			z-index: $zindex-dropdown;
			width: 100%;
			max-height: 19rem;
			overflow-y: scroll;
			box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
			border: 1px solid rgba(0, 0, 0, 0.2);
			position: absolute;
		}
		li {
			color: #333;
			list-style-type: none;
			margin: 0;
			cursor: pointer;
			padding: 0.6rem;
			border-bottom: 1px solid rgba(0, 0, 0, 0.2);
		}
	}
}
