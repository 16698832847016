.card-contributions {
	@extend %card-stats;

	.card-description {
		max-width: 350px;
		margin: 0 auto;
		margin-bottom: 20px;
	}

	.card-title {
		padding-top: 35px;
	}

	.card-stats {
		display: flex;
		align-items: center;
		flex-direction: row;
		padding: 11px;
	}

	.card-footer {
		[class*='col-']:not(:first-child):before {
			content: '';
			position: absolute;
			left: 0;
			width: 1px;
			height: 100%;
			background-color: $hr-line;
		}
	}

	.bootstrap-switch {
		margin: 0;
	}

	span {
		padding-left: 15px;
		text-align: left;
		max-width: 125px;
	}
}
