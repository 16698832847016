@media screen and (max-width: 991px) {
	#minimizeSidebar {
		display: none;
	}

	.navbar {
		box-shadow: 0 6px 10px -4px rgba(0, 0, 0, 0.15);

		.container-fluid {
			padding-top: 1rem;
			padding-right: 1rem;
			padding-left: 1rem;
			padding-bottom: 0.5rem;
		}

		.navbar-collapse {
			.input-group {
				margin: 0;
				margin-top: 5px;
			}
		}

		// .navbar-nav {
		// 	.nav-item:first-child {
		// 		margin-top: 10px;
		// 	}
		// 	.nav-item:not(:last-child) {
		// 		margin-bottom: 10px;
		// 	}
		// }

		.navbar-toggler-bar {
			display: block;
			position: relative;
			width: 22px;
			height: 1px;
			border-radius: 1px;
			background: $default-color;

			& + .navbar-toggler-bar {
				margin-top: 7px;
			}

			& + .navbar-toggler-bar.navbar-kebab {
				margin-top: 3px;
			}

			&.bar2 {
				width: 17px;
				transition: width 0.2s linear;
			}
		}

		&.bg-white:not(.navbar-transparent) .navbar-toggler-bar {
			background-color: $default-color;
		}

		& .toggled .navbar-toggler-bar {
			width: 24px;

			& + .navbar-toggler-bar {
				margin-top: 5px;
			}
		}
	}

	.wrapper {
		@include transition(0.5s, cubic-bezier(0.685, 0.0473, 0.346, 1));
	}

	// .nav-open{
	//     .main-panel{
	//         right: 0;
	//         @include transform-translate-x(260px);
	//     }

	//     .sidebar{
	//         @include transform-translate-x(0px);
	//     }

	//     body{
	//         position: relative;
	//         overflow-x: hidden;
	//     }

	//     .menu-on-right{
	//         .main-panel{
	//             @include transform-translate-x(-260px);
	//         }

	//         .navbar-collapse,
	//         .sidebar{
	//             @include transform-translate-x(0px);
	//         }

	//         .navbar-translate{
	//             @include transform-translate-x(-300px);
	//         }

	//         #bodyClick{
	//             right: 260px;
	//             left: auto;
	//         }
	//     }
	// }

	.menu-on-right {
		.sidebar {
			left: auto;
			right: 0;
			@include transform-translate-x(260px);
		}
	}

	.bar1,
	.bar2,
	.bar3 {
		outline: 1px solid transparent;
	}
	.bar1 {
		top: 0px;
		@include bar-animation($topbar-back);
	}
	.bar2 {
		opacity: 1;
	}
	.bar3 {
		bottom: 0px;
		@include bar-animation($bottombar-back);
	}
	.toggled .bar1 {
		top: 6px;
		@include bar-animation($topbar-x);
	}
	.toggled .bar2 {
		opacity: 0;
	}
	.toggled .bar3 {
		bottom: 6px;
		@include bar-animation($bottombar-x);
	}

	@include topbar-x-rotation();
	@include topbar-back-rotation();
	@include bottombar-x-rotation();
	@include bottombar-back-rotation();

	@-webkit-keyframes fadeIn {
		0% {
			opacity: 0;
		}
		100% {
			opacity: 1;
		}
	}
	@-moz-keyframes fadeIn {
		0% {
			opacity: 0;
		}
		100% {
			opacity: 1;
		}
	}
	@keyframes fadeIn {
		0% {
			opacity: 0;
		}
		100% {
			opacity: 1;
		}
	}

	#bodyClick {
		height: 100%;
		width: 100%;
		position: fixed;
		opacity: 1;
		top: 0;
		right: 0;
		left: 260px;
		content: '';
		z-index: 9999;
		overflow-x: hidden;
		background-color: transparent;
		@include transition(0.5s, cubic-bezier(0.685, 0.0473, 0.346, 1));
	}

	.footer {
		.copyright {
			text-align: right;
		}
	}

	.section-nucleo-icons .icons-container {
		margin-top: 65px;
	}

	.navbar-nav {
		.nav-link {
			i.fa,
			i.nc-icon {
				opacity: 0.5;
			}
		}
	}

	.sidebar,
	.bootstrap-navbar {
		position: fixed;
		display: block;
		top: 0;
		height: 100%;
		width: 260px;
		right: auto;
		left: 0;
		z-index: 1032;
		visibility: visible;
		overflow-y: visible;
		padding: 0;
		@include transition(0.5s, cubic-bezier(0.685, 0.0473, 0.346, 1));

		@include transform-translate-x(-260px);
	}

	.main-panel {
		width: 100%;
	}

	.timeline {
		&:before {
			left: 5% !important;
		}

		> li > .timeline-badge {
			left: 5% !important;
		}

		> li > .timeline-panel {
			float: right !important;
			width: 82% !important;

			&:before {
				border-left-width: 0 !important;
				border-right-width: 15px !important;
				left: -15px !important;
				right: auto !important;
			}

			&:after {
				border-left-width: 0 !important;
				border-right-width: 14px !important;
				left: -14px !important;
				right: auto !important;
			}
		}
	}
}
@media (max-width: 991px) and (min-width: 768px) {
	.nav-tabs-navigation.verical-navs {
		padding: 0px 2px;
	}
}

@media screen and (min-width: 768px) {
	.footer {
		.footer-nav {
			padding-left: 21px;
		}

		.credits {
			padding-right: 15px;
		}
	}
}

@media screen and (min-width: 992px) {
	.navbar-collapse {
		background: none !important;
	}

	.navbar .navbar-toggle {
		display: none;
	}

	.navbar-nav {
		.nav-link {
			&.profile-photo {
				padding: 0;
				margin: 7px $padding-base-horizontal;
			}
		}
	}

	.section-nucleo-icons .icons-container {
		margin: 0 0 0 auto;
	}

	.footer {
		.copyright {
			float: right;
			padding-right: 15px;
		}
	}

	// .sidebar {
	// 	.sidebar-wrapper {
	// 		li.active {
	// 			> a:not([data-toggle='collapse']),
	// 			> [data-toggle='collapse'] + div .nav li {
	// 				&:before {
	// 					border-right: 17px solid $medium-gray;
	// 					border-top: 17px solid transparent;
	// 					border-bottom: 17px solid transparent;
	// 					content: '';
	// 					display: inline-block;
	// 					position: absolute;
	// 					right: -16px;
	// 					opacity: 1;
	// 					top: 7px;
	// 					transition: opacity 150ms ease-in;
	// 				}

	// 				&:after {
	// 					border-right: 17px solid $default-body-bg;
	// 					border-top: 17px solid transparent;
	// 					border-bottom: 17px solid transparent;
	// 					content: '';
	// 					display: inline-block;
	// 					position: absolute;
	// 					right: -17px;
	// 					opacity: 1;
	// 					top: 7px;
	// 					transition: opacity 150ms ease-in;
	// 				}
	// 			}
	// 			> [data-toggle='collapse'] + div .nav li {
	// 				a {
	// 					&:before,
	// 					&:after {
	// 						top: 0;
	// 					}
	// 				}
	// 			}
	// 		}
	// 	}
	// }
}

@media screen and (max-width: 768px) {
	.card-stats [class*='col-'] .statistics::after {
		display: none;
	}

	.main-panel .content {
		padding-left: 15px;
		padding-right: 15px;
	}

	.footer {
		nav {
			display: block;
			margin-bottom: 5px;
			float: none;
		}
	}

	.landing-page .section-story-overview .image-container:nth-child(2) {
		margin-left: 0;
		margin-bottom: 30px;
	}

	.card {
		.form-horizontal {
			.col-md-3.col-form-label {
				text-align: left;
			}
		}
	}
}

@media screen and (max-width: 767px) {
	.nav-tabs-navigation.verical-navs {
		padding: 0 28px;
	}

	.typography-line {
		padding-left: 23% !important;

		span {
			width: 60px !important;
		}
	}

	.login-page,
	.lock-page,
	.register-page {
		.navbar {
			padding: 0.5rem 1rem;
		}
	}

	.footer {
		.footer-nav,
		.credits {
			margin: 0 auto !important;
		}

		.footer-nav {
			margin-bottom: 10px !important;
		}
	}

	.register-page {
		.content {
			padding-top: 5vh;
		}
		.footer {
			position: relative;
		}
		.info-area.info-horizontal {
			margin-top: 0;
		}
	}
}

@media screen and (max-width: 374px) {
	.login-page {
		.content {
			padding-top: 10vh;
		}
	}
}

@media screen and (max-width: 576px) {
	.navbar[class*='navbar-toggleable-'] .container {
		margin-left: 0;
		margin-right: 0;
	}

	.card-contributions .card-stats {
		flex-direction: column;

		.bootstrap-switch {
			margin-bottom: 15px;
		}
	}

	.footer {
		.copyright {
			text-align: center;
		}
	}

	.section-nucleo-icons {
		.icons-container {
			i {
				font-size: 30px;

				&:nth-child(6) {
					font-size: 48px;
				}
			}
		}
	}

	.page-header {
		.container h6.category-absolute {
			width: 90%;
		}
	}

	.card-timeline .timeline {
		.timeline-panel {
			width: 38%;
			padding: 15px;
		}
	}
}
