.filters {
	width: 100%;
	.form-check {
		margin-bottom: 0;
	}
	div#advanced {
		width: 100%;
		margin: 0 0.25rem;
	}
	.showAdvanced {
		max-height: 500px;
		transition: max-height 0.4s ease-in;
		clear: both;
	}
	.hideAdvanced {
		max-height: 0;
		transition: max-height 0.25s ease-out;
		clear: both;
	}
	.overflowHidden {
		overflow: hidden;
	}
	.ui.form .fields {
		margin: 0;
		line-height: 2rem;
	}

	.showAdvancedButton {
		line-height: 2.4rem;
		float: right;
		font-size: 0.8rem;
		width: 100%;
		text-align: center;
		clear: both;
		i {
			margin: 0 0.5rem;
		}
		.show-icon-up {
			transition: all 0.4s ease;
		}
		.show-icon-down {
			transition: all 0.4s ease;
			transform: rotate(180deg);
		}

		@media screen and (max-width: 576) {
			float: none;
			margin: 0 auto;
		}
	}
}
